import { Button, emptyFragment, TextField } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { dashboardUrl } from '../../../ClientRoutes';
import EditPasswordStore from '../../../EditPasswordStore';
import { T } from '../../../Translations';
import * as style from '../../ResetPassword/style.module.css';

interface Props {
  store: EditPasswordStore;
}

const Form: React.FC<Props> = ({ store }) => {
  const setPassword = (event: React.FormEvent<HTMLInputElement>) => {
    store.setPassword(event.currentTarget.value);
  };

  const setPasswordConfirmation = (event: React.FormEvent<HTMLInputElement>) => {
    store.setPasswordConfirmation(event.currentTarget.value);
  };

  const submit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    store.creating();
  };

  switch (store.state.kind) {
    case 'creating':
    case 'ready':
    case 'ready-with-client-error':
    case 'ready-with-server-error':
      return (
        <>
          <div>
            <form className={style.editPasswordForm}>
              <div className={style.field}>
                <TextField
                  type="password"
                  autoFocus
                  id="password"
                  onChange={setPassword}
                  name="password"
                  data-test-input-email={true}
                  value={store.password.getOrElseValue('')}
                  label={<T kind="Password" />}
                  labelProps={{ className: 'uppercase tracking-wider' }}
                />
              </div>
              <div className={style.field}>
                <TextField
                  type="password"
                  id="password"
                  onChange={setPasswordConfirmation}
                  name="passwordConfirmation"
                  data-test-input-email={true}
                  value={store.passwordConfirmation.getOrElseValue('')}
                  label={<T kind="Confirm Password" />}
                  labelProps={{ className: 'uppercase tracking-wider' }}
                />
              </div>
              <Button
                type="submit"
                color="green"
                radius="sm"
                onClick={submit}
                data-test-action-reset-password={true}
              >
                <T kind="Set New Password" />
              </Button>
            </form>
          </div>
          <br />
          <div>
            <Link to={dashboardUrl} className={style.link} data-test-login-link={true}>
              <T kind="Sign In" />
            </Link>
          </div>
        </>
      );
    case 'loading':
    case 'waiting':
    case 'error':
      return emptyFragment();
  }
};
export default observer(Form);
