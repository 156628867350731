import { just, Maybe } from 'maybeasy';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Typography } from '../../components/UI';
import { AlreadyTranslated, AlreadyTranslatedText, T } from '../../Translations';
import { HtmlView } from './Common/Experience';
import { SharedInvitationResource } from './SharedOpenEnrollment/SharedInvitationResourceStore/Types';

interface TitleAndDescription {
  title: React.ReactElement;
  content: React.ReactElement;
}

interface Props {
  sharedInvitationResource: SharedInvitationResource;
}

const RegistrationHeaderTitleAndDescription: React.FC<Props> = ({ sharedInvitationResource }) => {
  const message = (): Maybe<AlreadyTranslatedText> => {
    switch (sharedInvitationResource.payload.loginMessageType) {
      case 'use-case-login-message':
        return sharedInvitationResource.payload.loginMessage;
      case 'use-case-description':
        return sharedInvitationResource.payload.description;
    }
  };

  const { title, content }: TitleAndDescription = just({})
    .assign('content', message)
    .assign('title', sharedInvitationResource.payload.name)
    .map(({ content, title }) => ({
      title: <AlreadyTranslated content={title} />,
      content: (
        <HtmlView
          className="max-w-[900px] text-font-size-sm xl:text-font-size-md"
          description={content}
        />
      ),
    }))
    .getOrElse(() => ({
      title: <T kind="Get ready to grow with ExecOnline" />,
      content: (
        <Typography>
          <T
            kind="{{orgName}} is partnering with ExecOnline to provide highly actionable ..."
            orgName={sharedInvitationResource.payload.organization.payload.name}
          />
        </Typography>
      ),
    }));

  return (
    <>
      <Typography className="mb-4" variant="h3" component="h1" fontWeight="medium">
        {title}
      </Typography>
      <div className="mb-4">{content}</div>
    </>
  );
};

export default observer(RegistrationHeaderTitleAndDescription);
