import { Button, TextField } from '@execonline-inc/execonline-ui';
import clsx from 'clsx';
import { observer } from 'mobx-react';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { dashboardUrl } from '../../../ClientRoutes';
import ResetPasswordStore from '../../../ResetPasswordStore';
import { T } from '../../../Translations';
import Loader from '../../Loader';
import * as style from '../style.module.css';

interface Props {
  store: ResetPasswordStore;
}

const Form: React.FC<Props> = ({ store }) => {
  const setEmail = (event: React.FormEvent<HTMLInputElement>) => {
    store.setEmail(event.currentTarget.value);
  };

  const submit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    store.creating();
  };

  switch (store.state.kind) {
    case 'creating':
    case 'requested':
    case 'error':
    case 'ready': {
      return (
        <>
          <form>
            <div className={style.field}>
              <TextField
                autoFocus
                id="login_email"
                onChange={setEmail}
                data-test-input-email={true}
                value={store.email.getOrElseValue('')}
                label={<T kind="Email Address" />}
                labelProps={{ className: 'uppercase tracking-wider' }}
                helperText="email@domain.com"
                helperTextProps={{ className: 'font-medium' }}
              />
            </div>
            <Button
              type="submit"
              color="green"
              radius="sm"
              onClick={submit}
              data-test-action-reset-password={true}
            >
              <T kind="Confirm Reset Password" />
            </Button>
          </form>
          <br />
          <Link
            to={dashboardUrl}
            className={clsx(
              'inline-block flex-auto cursor-pointer whitespace-nowrap rounded-sm px-4 py-2 text-center text-sm font-light md:mr-2',
              'border border-solid border-gray-600-old bg-transparent text-gray-600-old hover:border-gray-800-old hover:bg-gray-50-old hover:text-gray-800-old',
            )}
            data-test-login-link={true}
          >
            <T kind="Sign In" />
          </Link>
        </>
      );
    }
    case 'loading':
    case 'waiting':
      return <Loader />;
  }
};
export default observer(Form);
