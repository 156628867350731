import { emptyFragment, FileIcon } from '@execonline-inc/execonline-ui';
import { observer } from 'mobx-react';
import { AlreadyTranslated } from '../../Translations';
import DownloadViewModal from '../DownloadViewModal';
import { useOpenable } from '../Openable';
import * as style from './style.module.css';
import { Linkables } from './Types';

interface Props {
  linkables: Linkables;
}

function Linkable({ linkables }: Props) {
  const { openableState, open, close } = useOpenable();

  return linkables
    .map((l) => l.first)
    .map((linkable) => (
      <div>
        <button className={style.downloadButton} onClick={open}>
          <div className={style.buttonIcon}>
            <FileIcon size="sm" color="inherit" />
          </div>
          <span>
            <AlreadyTranslated content={linkable.label} />
          </span>
        </button>
        <DownloadViewModal resource={linkable.resource} close={close} modalState={openableState} />
      </div>
    ))
    .getOrElse(emptyFragment);
}

export default observer(Linkable);
