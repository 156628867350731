import { tailwindTheme } from '../Native/tailwind-theme';
import { DarkTheme, LightTheme } from '../Themes/ThemeContext/Types';

export const lightTheme: LightTheme = {
  kind: 'light',
  colors: {
    'bg-primary-color': tailwindTheme.colors.white,
    'bg-secondary-color': tailwindTheme.colors.neutral['50'],
    'bg-tertiary-color': tailwindTheme.colors.white,
    'text-primary-color': tailwindTheme.colors.neutral['900'],
    'text-secondary-color': tailwindTheme.colors.neutral['300'],
    'text-white-color': tailwindTheme.colors.white,
    'text-black-color': tailwindTheme.colors.black,
    'title-text-color': '#3A835D',
    'text-dark-color': '#2b2b2b',
    'text-inherit-color': tailwindTheme.colors.inherit,
    'bg-quaternary-color': tailwindTheme.colors.neutral['50'],
    'logo-primary-color': tailwindTheme.colors.logo['300'],
    'logo-secondary-color': tailwindTheme.colors.neutral['900'],
    'primary-color': tailwindTheme.colors.primary['500'],
    'link-color': tailwindTheme.colors.teal['600'],
    'success-color': tailwindTheme.colors.green['600'],
    'warning-color': tailwindTheme.colors.amber['300'],
    'danger-color': tailwindTheme.colors.red['600'],
    'disabled-input-bg-color': tailwindTheme.colors.zinc['200'],
    'primary-hover-color': tailwindTheme.colors.primary['500'],
    'link-hover-color': tailwindTheme.colors.teal['600'],
    'success-hover-color': tailwindTheme.colors.green['700'],
    'warning-hover-color': tailwindTheme.colors.amber['300'],
    'danger-hover-color': tailwindTheme.colors.red['700'],
    'body-bg': tailwindTheme.colors.white, // bg-primary-color
    'body-text': tailwindTheme.colors.neutral['900'], // text-primary-color
    'overlay-color': '#82a0a799',
    'apple-icon-bg-color': tailwindTheme.colors.black,
    'apple-icon-text-color': tailwindTheme.colors.white,
    'icon-bg': tailwindTheme.colors.gray['100'],
    'list-view-btn-primary': tailwindTheme.colors.green['600'],
    'list-view-btn-secondary': tailwindTheme.colors.teal['600'],
    /* button colors */
    'button-primary-solid-color': '#1a8927',
    'button-primary-solid-text-color': tailwindTheme.colors.white,
    'button-primary-outlined-color': '#1a8927',
    'button-primary-outlined-text-color': '#1a8927',
    'button-primary-text-color': '#1a8927',
    'button-primary-text-text-color': '#1a8927',
    'button-secondary-solid-color': tailwindTheme.colors.teal['600'],
    'button-secondary-solid-text-color': tailwindTheme.colors.white,
    'button-secondary-outlined-color': tailwindTheme.colors.teal['600'],
    'button-secondary-outlined-text-color': tailwindTheme.colors.teal['600'],
    'button-secondary-text-color': tailwindTheme.colors.teal['600'],
    'button-secondary-text-text-color': tailwindTheme.colors.teal['600'],
    'button-dark-solid-color': '#2b2b2b',
    'button-dark-solid-text-color': tailwindTheme.colors.white,
    'button-dark-outlined-color': '#2b2b2b',
    'button-dark-outlined-text-color': '#2b2b2b',
    'button-dark-text-color': '#2b2b2b',
    'button-dark-text-text-color': '#2b2b2b',
    'button-gray-solid-color': tailwindTheme.colors.gray['600'],
    'button-gray-solid-text-color': tailwindTheme.colors.white,
    'button-gray-outlined-color': tailwindTheme.colors.gray['600'],
    'button-gray-outlined-text-color': tailwindTheme.colors.gray['600'],
    'button-gray-text-color': tailwindTheme.colors.gray['600'],
    'button-gray-text-text-color': tailwindTheme.colors.gray['600'],
    'button-black-solid-color': tailwindTheme.colors.black,
    'button-black-solid-text-color': tailwindTheme.colors.white,
    'button-black-outlined-color': tailwindTheme.colors.black,
    'button-black-outlined-text-color': tailwindTheme.colors.black,
    'button-black-text-color': tailwindTheme.colors.black,
    'button-black-text-text-color': tailwindTheme.colors.black,
    'button-white-solid-color': tailwindTheme.colors.white,
    'button-white-solid-text-color': tailwindTheme.colors.teal['600'],
    'button-white-outlined-color': tailwindTheme.colors.white,
    'button-white-outlined-text-color': tailwindTheme.colors.white,
    'button-white-text-color': tailwindTheme.colors.white,
    'button-white-text-text-color': tailwindTheme.colors.white,
    /* badge colors */
    'badge-danger-bg-color': '#B3090F',
    'badge-gray-bg-color': '#F3F4F6',
    'badge-light-bg-color': '#F2F2F3', // tailwindTheme.colors.gray[50]
    /* Icon colors */
    'icon-primary-color': tailwindTheme.colors.neutral['900'],
    'icon-secondary-color': tailwindTheme.colors.zinc['500'],
    'icon-white-color': tailwindTheme.colors.white,
    'icon-black-color': tailwindTheme.colors.black,
    'icon-gray-color': '#404040',
    'icon-disabled-color': '#BEC0C5',
    'icon-inherit-color': tailwindTheme.colors.inherit,
    /*Carousel colors */
    'carousel-indicator-active-color': '#2B2B2B',
    'carousel-indicator-color': '#C0C0C0',
  },
  images: {
    'login-bg': 'login-bg-light.svg',
  },
};

export const darkTheme: DarkTheme = {
  kind: 'dark',
  colors: {
    'bg-primary-color': tailwindTheme.colors.neutral['800'],
    'bg-secondary-color': tailwindTheme.colors.black,
    'bg-tertiary-color': tailwindTheme.colors.slate['800'],
    'text-primary-color': tailwindTheme.colors.neutral['200'],
    'text-secondary-color': tailwindTheme.colors.neutral['500'],
    'text-white-color': tailwindTheme.colors.white,
    'title-text-color': '#fff',
    'bg-quaternary-color': '#262626',
    'text-black-color': tailwindTheme.colors.black,
    'text-dark-color': tailwindTheme.colors.white,
    'text-inherit-color': tailwindTheme.colors.inherit,
    'logo-primary-color': tailwindTheme.colors.logo['300'],
    'logo-secondary-color': tailwindTheme.colors.white,
    'primary-color': tailwindTheme.colors.primary['500'],
    'primary-hover-color': tailwindTheme.colors.primary['600'],
    'link-color': tailwindTheme.colors.white,
    'link-hover-color': tailwindTheme.colors.primary['500'],
    'danger-color': tailwindTheme.colors.red['400'],
    'danger-hover-color': tailwindTheme.colors.red['700'],
    'success-color': tailwindTheme.colors.green['600'],
    'success-hover-color': tailwindTheme.colors.green['700'],
    'warning-color': tailwindTheme.colors.amber['300'],
    'warning-hover-color': tailwindTheme.colors.amber['400'],
    'disabled-input-bg-color': tailwindTheme.colors.zinc['400'],
    'body-bg': tailwindTheme.colors.neutral['800'], // bg-primary-color
    'body-text': tailwindTheme.colors.neutral['200'], // text-primary-color
    'overlay-color': '#00000080',
    'apple-icon-bg-color': tailwindTheme.colors.white,
    'apple-icon-text-color': tailwindTheme.colors.black,
    'icon-bg': tailwindTheme.colors.neutral['700'],
    'list-view-btn-primary': tailwindTheme.colors.green['500'],
    'list-view-btn-secondary': tailwindTheme.colors.teal['400'],
    /* button colors */
    'button-primary-solid-color': '#1a8927',
    'button-primary-solid-text-color': tailwindTheme.colors.white,
    'button-primary-outlined-color': '#1a8927',
    'button-primary-outlined-text-color': '#1a8927',
    'button-primary-text-color': '#1a8927',
    'button-primary-text-text-color': '#1a8927',
    'button-secondary-solid-color': tailwindTheme.colors.teal['600'],
    'button-secondary-solid-text-color': tailwindTheme.colors.white,
    'button-secondary-outlined-color': tailwindTheme.colors.teal['600'],
    'button-secondary-outlined-text-color': tailwindTheme.colors.teal['600'],
    'button-secondary-text-color': tailwindTheme.colors.teal['600'],
    'button-secondary-text-text-color': tailwindTheme.colors.teal['600'],
    'button-dark-solid-color': tailwindTheme.colors.white,
    'button-dark-solid-text-color': '#2b2b2b',
    'button-dark-outlined-color': tailwindTheme.colors.white,
    'button-dark-outlined-text-color': tailwindTheme.colors.white,
    'button-dark-text-color': '#2b2b2b',
    'button-dark-text-text-color': tailwindTheme.colors.white,
    'button-gray-solid-color': tailwindTheme.colors.gray['600'],
    'button-gray-solid-text-color': tailwindTheme.colors.white,
    'button-gray-outlined-color': tailwindTheme.colors.gray['600'],
    'button-gray-outlined-text-color': tailwindTheme.colors.gray['600'],
    'button-gray-text-color': tailwindTheme.colors.gray['600'],
    'button-gray-text-text-color': tailwindTheme.colors.gray['600'],
    'button-black-solid-color': tailwindTheme.colors.black,
    'button-black-solid-text-color': tailwindTheme.colors.white,
    'button-black-outlined-color': tailwindTheme.colors.black,
    'button-black-outlined-text-color': tailwindTheme.colors.black,
    'button-black-text-color': tailwindTheme.colors.black,
    'button-black-text-text-color': tailwindTheme.colors.black,
    'button-white-solid-color': tailwindTheme.colors.white,
    'button-white-solid-text-color': tailwindTheme.colors.teal['600'],
    'button-white-outlined-color': tailwindTheme.colors.white,
    'button-white-outlined-text-color': tailwindTheme.colors.white,
    'button-white-text-color': tailwindTheme.colors.white,
    'button-white-text-text-color': tailwindTheme.colors.white,
    /* badge colors */
    'badge-danger-bg-color': '#B3090F',
    'badge-gray-bg-color': '#404040',
    'badge-light-bg-color': '#2B2B2B',
    /* Icon colors */
    'icon-primary-color': tailwindTheme.colors.neutral['200'],
    'icon-secondary-color': tailwindTheme.colors.neutral['500'],
    'icon-white-color': tailwindTheme.colors.white,
    'icon-black-color': tailwindTheme.colors.black,
    'icon-gray-color': '#EAEAEB',
    'icon-disabled-color': '#555555',
    'icon-inherit-color': tailwindTheme.colors.inherit,
    /*Carousel colors */
    'carousel-indicator-active-color': '#FFFFFF',
    'carousel-indicator-color': '#BEC0C5',
  },
  images: {
    'login-bg': 'login-bg.jpg',
  },
};
